export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
};

export const handleCopy = (textToCopy, setCopied) => {
  const copyText = () => {
    try {
      navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 300); // Reset copied state after 1.5 seconds
    } catch (error) {
      console.error("Error copying to clipboard: ", error);
    }
  };
  copyText();
};

export const formatText = (text) => {
  // Regex to find numbers followed by a period and a space
  const regex = /(\d+\. )/g;

  // Split the text into parts based on the numbered points
  const parts = text.split(regex);

  // Format the numbered points
  let formattedPoints = [];
  let stopFormatting = false;
  parts.slice(1).forEach((part, index) => {
    if (!stopFormatting) {
      const point = `${index + 1}. ${part.trim()}`;
      const isLastPoint = point.endsWith(".");
      formattedPoints.push(isLastPoint ? point : `\n${point}`);
      stopFormatting = isLastPoint;
    } else {
      formattedPoints.push("\n" + part);
    }
  });

  // Join the parts back together
  const formattedText = [parts[0], ...formattedPoints].join("");

  return formattedText;
};
