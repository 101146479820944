import React from 'react'
import { DR_BIOHACKER } from '../../utils/constants';

const AboutUs = () => {
    return (
        <div className=" privacy-policy terms-of-use">
            <div >
                <h3 className="page-heading">About Us </h3>
                <p >
                    {DR_BIOHACKER} is here to give you the very best in medical advice from all sources,
                    aggregated right here on this site. The top section where you ask questions will show
                    you all of the conventional wisdom,accepted by the Medical Establishment and Big Pharma
                    .... yes we said it. As you know, at times, perhaps such info is not the best out there,
                    as it is given to you by your own doctors as well. We ALWAYS advise you to seek your medical
                    professional, but knowing how political the medical arena now is in this age of DEI,
                    Marxism and Woke universities and agencies, the section below the chat may be of use to you.
                </p>
                <p >
                    For instance... Take Cholesterol as an example. You just might save your life by viewing the videos below,
                    as cholesterol is like the fire department, sent to where there is a fire in your blood. Fighting the fire department seems nonsensical then,
                    no? Take the hint from this alone, as we, here at {DR_BIOHACKER}, believe you should help your doctor to find out what is causing the fire,
                    and stop the cause... seems logical, right? Well, logic conflicts with governments and those wishing to make money from you forever as you are stuck on medications to stop the fire department in your blood.
                    Get it? As I said, always seek medical advice and a second opinion, and use the videos below as your reference point to then question your own primary care physician.
                </p>
                <p >
                    If you are accessing this site via your mobile device, when you ask questions you cannot see the reference links that also pop up after every question,
                    designed to help you research and gather info on whatever you ask of {DR_BIOHACKER}. On desktop these pop on just to the right,
                    but on mobile, you need to click on top where it says Info.
                </p>
                <p >
                    If you would like to submit a good video or link that you think fellow readers here would find useful, please email it to us at  editor@drbiohacker.com
                </p>
            </div>
        </div>
    );
}

export default AboutUs
