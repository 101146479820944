import React, { useState } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import './Contact.scss'
import { ToastContainer, toast } from "react-toastify";
import { sendEmailForContact } from "../../api/common";

const ContactUs = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const initialFormData = {
    name: '',
    phoneNumber: '',
    message: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({
    name: false,
    phoneNumber: false,
    message: false
  });
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: value.trim() === '' });

    if (name === 'email') {
      setEmailError(!value || !/^\S+@\S+\.\S+$/.test(value));
      setEmail(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError(true);
    }
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      newErrors[key] = formData[key].trim() === '';
    });

    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    try {
      const data = {
        sender_email: email,
        subject: "Contact Us",
        body: JSON.stringify(formData)
      }
      const response = (await sendEmailForContact(data)).data;
      if (!!response) {
        toast.success("Details have been successfully sent.");
        setFormData(initialFormData);
        setEmail('');
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error('Error fetching data:', error);
    }

  };

  return (
    <section className="contact">
      <ToastContainer theme="colored" />
      <Container maxWidth="lg">
        <div className="contactInner">
          <div>
            <Typography variant="h4" className="text-center">
              Contact Us
            </Typography>
            <br />
            {/* <p>If you need to contact us for any reason, please email us at <a href="mailto: editor@drbiohacker.com"> editor@drbiohacker.com</a> using the form below</p> */}
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <h2>Contact Details</h2>
              <br />
              <p>If you need to contact us for any reason, please email us at <a href="mailto: editor@drbiohacker.com"> editor@drbiohacker.com</a> </p>
              <br />
              <p>Or </p>
              <br />
              <p>You can send snail mail as well at the following address:</p>
              <br />
              <address>
                <h3> Dr. Biohacker <br />  6805 Lebanon Rd, Suite 512 <br /> Frisco, Texas 75034 United States.</h3>
              </address>
              <br />

            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="">
                <form className="" onSubmit={handleSubmit}>
                  <TextField
                    className="mt-20"
                    label="Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    error={errors.name}
                    helperText={errors.name ? 'Name field required' : ''}
                  />
                  <br />
                  <br />
                  <TextField
                    className="mt-20"
                    label="Phone Number"
                    type="tel"
                    name="phoneNumber"
                    variant="outlined"
                    fullWidth
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    error={errors.phoneNumber}
                    helperText={errors.phoneNumber ? 'Phone number field required' : ''}
                  />
                  <br />
                  <br />
                  <TextField
                    label="Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    error={emailError}
                    helperText={emailError ? 'Email field required' : ''}
                  />
                  <br />
                  <br />
                  <TextField
                    className="mt-20"
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={formData.message}
                    onChange={handleChange}
                    error={errors.message}
                    helperText={errors.message ? 'Message field required' : ''}
                  />
                  <br />
                  <br />
                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-theme mt-20"
                    fullWidth
                  >
                    Send Email
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div></div>
    </section>
  );
};

export default ContactUs;
