import React, {useEffect} from "react";
import { Box, Typography, Button } from "@mui/material";
import { getSession } from "../../api/common";
import { useNavigate } from "react-router-dom";
import "./Pricing.scss";
import { ToastContainer, toast } from "react-toastify";
import { DR_BIOHACKER } from "../../utils/constants";
import { SUBSCRIPTION_PRICE } from "../../utils/constants";


const Pricing = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const handleCheckout = async () => {
    try {
      const expirationTime = localStorage.getItem("subscriptionExpireTime");
      const currentTime = new Date().getTime();
      if ((currentTime < expirationTime) && expirationTime != 'null') {
        toast.error('You already have an active subscription.');
        return;
      }
      const data = {
        stripe_price_id: SUBSCRIPTION_PRICE,
        quantity: 1,
      };
      const session = (await getSession(data)).data.url;
      window.location.href = session;
    } catch (error) {
      console.error("An error occurred during checkout:", error);
    }
  };
  useEffect(() => {
    if (localStorage.getItem('subscriptionStatus') =='active') {
      navigate("/")
    }

  }, []);
  return (
    <div className="pricing">
    <Box paddingTop="">
    <ToastContainer theme="colored" />
      <Typography variant="h4" gutterBottom align="center" className="">
        <b>Start using {DR_BIOHACKER} for free!</b>
      </Typography>
      <Typography variant="body1" gutterBottom align="center" sx={{ mb: 4 }} color="text.secondary">
        Switch to <strong>{DR_BIOHACKER} Plus</strong> to use unlimited messages
        and more advanced features.
      </Typography>
      <Box className="pricing_card_parent" display="flex" justifyContent="center" alignItems="center" >
        <Box
        className="p_card"
          sx={{
            mr: 3,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            minHeight: "330px",
            minWidth: "267px"
          }}
        >
          <Typography variant="h6" gutterBottom className="p_member p_free">
            Free
          </Typography>
          <Typography variant="body1" gutterBottom style={{marginTop: '6px'}}>
            Use {DR_BIOHACKER} for Free
          </Typography>
          <Typography variant="h4" gutterBottom style={{marginTop: '5px'}}>
            <b>$0/mo</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            20 messages a month
          </Typography>
        </Box>

        <Box
          className="p_card"
          sx={{
            color: "rgb(245, 250, 255)",
            color: "black",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "330px",
            minWidth: "267px"
          }}
        >
          <Typography variant="h6" gutterBottom className="p_member p_premium">
            {DR_BIOHACKER} Member
          </Typography>
          <Typography variant="body1" gutterBottom>
            Only
          </Typography>
          <Typography variant="h4" gutterBottom>
            <b>$40/mo</b>
          </Typography>
          <Typography variant="body2" color="text.secondary">Unlimited messages a month</Typography>
          <div style={{padding: '0 20px', marginTop: '20px'}}>
          {isLoggedIn ? (
            <Button
              className="btnPrice"
              onClick={handleCheckout}
            >
              JOIN
            </Button>
          ) : (
            <Button
              className="btnPrice"
              onClick={() => navigate("/login")}
            >
              Sign in
            </Button>
          )}
          </div>
        </Box>
      </Box>
    </Box>
    </div>
  );
};

export default Pricing;
