import React, { useState, useEffect } from "react";
import paymentSuccessImg from "../../assets/images/payment-success.png";
import paymentFailedImg from "../../assets/images/payment-failed.png";

import { useNavigate } from "react-router-dom";

const PaymentSuccess = ({}) => {
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();

  const handlePaymentSuccess = async () => {
    setShowLoader(false);
    setTimeout(() => {
      navigate("/quote/payment");
    }, 4000);
  };

  useEffect(() => {
    handlePaymentSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="payment-page">
        <div className="">
          {showLoader ? (
            // <Spinner
            //   animation="border"
            //   variant="success"
            //   size="lg"
            //   className="mx-2 spinner-border-xl"
            //   style={{ width: '11rem', height: '11rem' }}
            // />
            ` Loading...`
          ) : (
            <>
              <div className="img-parent">
                <img
                  className="payment-success-img"
                  width="220"
                  src={paymentSuccessImg}
                  alt="logo"
                  onError={(e) => {
                    // If AVIF fails to load, fallback to PNG
                    e.target.src = paymentSuccessImg;
                  }}
                />
              </div>
              {/* <h3>{succesResponse}</h3> */}
              <p className="">
                Your Payment is Successful, Redirecting you in few seconds
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
