import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SpaIcon from "@mui/icons-material/Spa";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import RowingIcon from "@mui/icons-material/Rowing";
import { getNewOuraAccessToken, ouraCallback } from "../../api/common";
import { useNavigate } from "react-router-dom";
import OuraringShare from "../Shared/ouraringShare";
import { getOuraReadiness, getOuraActivity, getOuraSleep } from "../../api/common";
import Loader from "../Shared/Loader"

import "./Ouraring.scss";

const Ouraring = ({ isLoggedIn }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [ouraReadinessData, setouraReadinessData] = useState([]);
  const [ouraActivityData, setouraActivityData] = useState([]);
  const [ouraSleepData, setouraSleepData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const [sharedText, setSharedText] = useState({
    readInessText: {
      SCORE: "Readiness", CARD1: "Resting Heart Rate", CARD2: "Heart Rate Variability", CARD3: "Body Tempreature",
      CARD4: "Respiratory Rate", CRADBOX1: "Resting heart rate", CRADBOX2: "HRV balance", CRADBOX3: "Body tempreature", CRADBOX4: "Recovery Index", CRADBOX5: "Sleep", ICON: SpaIcon
    },
    activityText: {
      SCORE: "Activity", CARD1: "Average Activity Intensity", CARD2: "Total Calories", CARD3: "Walking Equivalency",
      CARD4: "Steps", CRADBOX1: "Stay active", CRADBOX2: "Move every hour ", CRADBOX3: "Meet daily goals", CRADBOX4: "Training frequency", CRADBOX5: "Training volume", ICON: RowingIcon
    },
    sleepText: {
      SCORE: "Sleep", CARD1: "Deep Sleep", CARD2: "Time in Bed", CARD3: "Sleep efficiency",
      CARD4: "Resting heart rate", CRADBOX1: "Total Sleep", CRADBOX2: "Efficiency", CRADBOX3: "Restfulness", CRADBOX4: "REM Sleep", CRADBOX5: "Deep sleep", ICON: BedtimeIcon
    }
  });

  const tabs = [
    { label: 'Readiness', icon: <SpaIcon />, index: 0 },
    { label: 'Sleep', icon: <BedtimeIcon />, index: 1 },
    { label: 'Activity', icon: <RowingIcon />, index: 2 }
  ];
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login")
    }
    const get_oura_access_token = localStorage.getItem("oura_access_token");
    if (get_oura_access_token && get_oura_access_token != 'null') {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    // Check if the URL has the code parameter (callback from Oura)
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");



    if (code && !isAuthenticated) {
      setIsLoader(true)
      async function fetchOuraCallbackData() {
        const data = {
          code: code
        }
        try {
          const response = (await ouraCallback(data)).data;
          if (response.status == true) {
            setIsAuthenticated(true);
            localStorage.setItem("oura_access_token", response.data.access_token);
            setIsLoader(false)
            navigate("/ouraring")
          } else {
            console.error("Failed to token:", data.message);
            setIsLoader(false)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoader(false)
        }
      }
      fetchOuraCallbackData()
    } else {
      ouraSleep();
    }
  }, [isAuthenticated]);

  const handleLogin = async () => {
    window.location.href = process.env.REACT_APP_OURA_RING_HREF;
  };


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      ouraSleep()
    } else if (newValue === 2) {
      ouraActivity()
    } else {
      ouraSleep();
    }
    setValue(newValue);
  };
  const ouraReadiness = async () => {
    try {
      const response = (await getOuraReadiness()).data;
      if (response.status == true) {
        setouraReadinessData([...ouraReadinessData, response.data]);
      }
    } catch (error) {

    }
  }
  const ouraActivity = async () => {
    try {
      setIsLoader(true);
      const response = (await getOuraActivity()).data;
      if (response.status == true) {
        setouraActivityData([...ouraActivityData, response.data]);
      } else {
        console.error("Error:", response?.data?.message);
      }
      setIsLoader(false);
    } catch (error) {
      console.error("Error:", error);
    }
    setIsLoader(false);
  }
  const ouraSleep = async () => {
    try {
      setIsLoader(true);
      const response = (await getOuraSleep()).data;
      if (response.status == true) {
        setouraSleepData([...ouraSleepData, response.data]);
      } else {
        console.error("Error:", response?.data?.message);
      }
      setIsLoader(false)
    } catch (error) {
      console.error("Error:", error);
      setIsLoader(false)
    }
  }
  return (
    <div className="ouraring">
      {isLoader && <Loader />}
      <Container maxWidth="lg">
        {isAuthenticated ? (
          <>
            <section>
              <Box className="d-set d-inline-sm">
                  <h1>Oura Ring</h1>
                  <a style={{color:'red', cursor:'pointer'}} onClick={ ()=> navigate("/")} >Discuss Your Oura Ring details with Dr. Biohacker</a>
              </Box>
              <br />
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    {tabs.map((tab, index) => (
                      <Tab
                        key={index}
                        icon={tab.icon}
                        label={tab.label}
                        {...a11yProps(tab.index)}
                      />
                    ))}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} className="tabsParen">
                  <OuraringShare sharedText={sharedText.readInessText} ouraDataList={ouraSleepData} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} className="tabsParen">
                  <OuraringShare sharedText={sharedText.sleepText} ouraDataList={ouraSleepData} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} className="tabsParen">
                  <OuraringShare sharedText={sharedText.activityText} ouraDataList={ouraActivityData} />
                </CustomTabPanel>
              </Box>
            </section>
          </>
        ) : (
          <div className="oura">
            <Button
              onClick={handleLogin}
              type="submit"
              variant="contained"
              className="btn-theme mt-20"
            >
              Login with Oura Ring
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Ouraring;
