import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  _addArticleLinks,
  _deleteLink,
  _getVideoLinks,
  _updateLink,
} from "../../api/common";
import { LinksHead } from "../../utils/constants";
import { handleCopy } from "../../utils/methods";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const linkPostions = [
  { name: "Left Bottom", value: "LEFT BOTTOM" },
  { name: "Right Bottom", value: "RIGHT BOTTOM" },
];

function Links() {
  const [blogLinks, setBlogLinks] = useState([]);
  const [isLinkUpdate, setIsLinkUpdate] = useState(false);
  const [copied, setCopied] = useState(false);
  const [linkPosition, setLinkPosition] = useState("");

  const [linkFormData, setLinkFormData] = useState({
    url: "",
    title: "",
  });

  const handleArticleInputChange = (event) => {
    const { name, value } = event.target;

    setLinkFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getVideoLinks = () => {
    _getVideoLinks().then((resp) => {
      if (resp.status === 200) {
        setBlogLinks(resp?.data?.web_link);
      } else {
        console.err(resp);
      }
    });
  };

  const addArticleLinks = async () => {
    const data = {
      url: linkFormData?.url,
      title: linkFormData?.title,
      link_position: linkPosition,
      token: localStorage.getItem("access_token")
    };

    if (linkFormData.title === "" && linkFormData.url === "" || linkPosition === "") {
      alert("Link, Area or description can't be blank");
      return;
    }

    try {
      const res = await _addArticleLinks(data);
      if (res.status === 200) {
        console.log("record saved successfully");
        setLinkFormData({
          url: "",
          title: "",
        });
        getVideoLinks();
      }
    } catch (error) { }
  };

  const updateLink = async () => {
    if (
      linkFormData.title === "" ||
      linkFormData.url === "" ||
      linkPosition === ""
    ) {
      alert("Link, Area or Video description can't be blank");
      return;
    }
    let data = {
      url: linkFormData?.url,
      title: linkFormData?.title,
      link_position: linkPosition,
    };
    let id = linkFormData?.id;
    try {
      const res = await _updateLink(data, id);
      if (res.status === 200) {
        alert("Record updated Successfully");
        setIsLinkUpdate(false);
        setLinkFormData({
          title: "",
          url: "",
          id: null,
        });
        getVideoLinks();
      } else {
        alert("Error Updating Record");
      }
    } catch (err) {
      console.log("err : ", err);
    }
  };

  const deleteLink = async (id) => {
    try {
      const res = await _deleteLink(id);
      if (res.status === 200) {
        alert("Record deleted Successfully");
        getVideoLinks();
      } else {
        alert("Error Updating Record");
      }
    } catch (err) {
      console.log("err : ", err);
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLinkPosition(value);
  };

  useEffect(() => {
    getVideoLinks();
  }, []);

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              pb: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>Links </h1>
            <p>Update your article links from here</p>
            <br />
            <Grid container spacing={2} sx={{ flexGrow: 1, p: 2 }}>
              <Grid xs={12}>
                <label>
                  <b>Select the area for the link to be visible</b>
                </label>
                <br />
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-name-label">Select an area</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={linkPosition}
                    onChange={handleChange}
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    {linkPostions?.map(({ name, value }, i) => (
                      <MenuItem key={i} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <label>
                  <b>Add your title of the link</b>
                </label>
                <br />
                <TextField
                  style={{ marginBlock: "10px", width: "100%" }}
                  label="Title of link"
                  size="small"
                  id="outlined-end-adornment"
                  sx={{ mb: 1, mr: 1, width: "25ch" }}
                  name="title"
                  onChange={handleArticleInputChange}
                  value={linkFormData?.title}
                />
              </Grid>
              <Grid xs={12} sx={{}}>
                <label>
                  <b>Add your article link</b>
                </label>
                <br />
                <TextField
                  style={{ marginBlock: "10px", width: "100%" }}
                  label="Article Link"
                  size="small"
                  id="outlined-end-adornment"
                  sx={{ mb: 1, mr: 1, width: "25ch" }}
                  name="url"
                  onChange={handleArticleInputChange}
                  value={linkFormData?.url}
                />
              </Grid>
              <Grid
                xs={12}
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {/* <label>
                                <b>Action</b>
                              </label>
                              <br /> */}
                {!isLinkUpdate ? (
                  <Button
                    style={{
                      marginBlock: "10px",
                      width: "35ch",
                    }}
                    variant="contained"
                    className=""
                    onClick={() => addArticleLinks()}
                  >
                    Add Link
                  </Button>
                ) : (
                  <Stack direction={"row"} spacing={1}>
                    <Button
                      style={{
                        marginBlock: "10px",
                        width: "35ch",
                      }}
                      variant="outlined"
                      className=""
                      onClick={() => {
                        setIsLinkUpdate(false);
                        setLinkFormData({
                          title: "",
                          url: "",
                          id: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      style={{
                        marginBlock: "10px",
                        width: "35ch",
                      }}
                      variant="contained"
                      className=""
                      onClick={() => {
                        updateLink();
                      }}
                    >
                      Update Link
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {LinksHead?.map((val, index) => {
                      return (
                        <React.Fragment>
                          {/* <TableCell key={index}>SN</TableCell> */}
                          <TableCell key={index}>{val}</TableCell>
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blogLinks?.map(
                    ({ title, url, id, link_position, update, delet }, index) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <React.Fragment>
                            {/* <TableCell component="th" scope="row">
                                            {index + 1}
                                          </TableCell> */}
                            <TableCell component="th" scope="row">
                              {title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Stack
                                direction={"row"}
                                sx={{ alignItems: "center" }}
                                className="link-parent"
                              >
                                <Tooltip title={url}>
                                  <div className="link-truncate">{url}</div>
                                </Tooltip>
                                <span
                                  className={`text-copy ${copied ? "copied" : null
                                    }`}
                                  onClick={() => handleCopy(url, setCopied)}
                                >
                                  {copied ? <DoneIcon /> : <ContentCopyIcon />}
                                </span>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {link_position}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Stack direction={"row"} spacing={1}>
                                <EditIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIsLinkUpdate(true);
                                    setLinkFormData({
                                      title: title,
                                      url: url,
                                      id: id,
                                    });
                                  }}
                                />
                                <DeleteIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    deleteLink(id);
                                  }}
                                />
                              </Stack>
                            </TableCell>
                          </React.Fragment>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Links;
