import React, { useState, useEffect } from "react";
import paymentFailedImg from "../../assets/images/payment-failed.png";

import { useNavigate } from "react-router-dom";

const PaymentFailed = ({}) => {
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();

  const handlePaymentSuccess = async () => {
    setShowLoader(false);
    setTimeout(() => {
      navigate("/");
    }, 4000);
  };

  useEffect(() => {
    handlePaymentSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="payment-page">
        <div className="">
          {showLoader ? (
            // <Spinner
            //   animation="border"
            //   variant="success"
            //   size="lg"
            //   className="mx-2 spinner-border-xl"
            //   style={{ width: '11rem', height: '11rem' }}
            // />
            ` Loading...`
          ) : (
            <>
              <div className="img-parent">
                <img
                  className="payment-success-img"
                  width="220"
                  src={paymentFailedImg}
                  alt="logo"
                  onError={(e) => {
                    // If AVIF fails to load, fallback to PNG
                    e.target.src = paymentFailedImg;
                  }}
                />
              </div>
              {/* <h3>{succesResponse}</h3> */}
              <p className="">
                Your transaction has failed due to some technical error.
                Redirecting you to Payments..Please try again.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
