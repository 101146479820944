import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';

export const Listitems = ({ setCurrentTab }) => (
  <React.Fragment>
    <ListItemButton onClick={() => setCurrentTab('videos')}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Videos" />
    </ListItemButton>
    <ListItemButton onClick={() => setCurrentTab('links')}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Links" />
    </ListItemButton>
    <ListItemButton onClick={() => setCurrentTab('ads')}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Ads" />
    </ListItemButton>
    <Link to="/" style={{textDecoration: "none", color: "#000000"}}>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Ask Questions" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

