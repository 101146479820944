import React, { useEffect, useState } from "react";
import SpaIcon from "@mui/icons-material/Spa";

import Grid from "@mui/material/Grid";

const OuraringShare = ({sharedText,ouraDataList}) => {
  const [ouraData,setOuraData] = useState()
  useEffect(() => {
    if (ouraDataList && ouraDataList[0] && ouraDataList[0][0]) {
      const newData = ouraDataList[0][0];
      if (JSON.stringify(newData) !== JSON.stringify(ouraData)) {
        setOuraData(newData);
      }
    }
  }, []);
  let DynamicIcon;
  switch (sharedText.SCORE) {
    case 'Activity':
      DynamicIcon = sharedText.ICON;
      break;
    case 'Sleep':
      DynamicIcon = sharedText.ICON;
      break;
    case 'Readiness':
      DynamicIcon = sharedText.ICON;
      break;
    default:
      DynamicIcon = SpaIcon;
  }
  return (
    <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="mainScore">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                           {sharedText.SCORE !='Sleep' && <p>{sharedText.SCORE} Score</p>}
                            { sharedText.SCORE ==='Sleep' &&<p>Total Sleep</p>}
                            {sharedText.SCORE !='Sleep' && <h1 className="font-60">
                              {ouraData?.readiness?.score ? ouraData?.readiness?.score || 0 : ouraData?.score || 0} <small className="font-30"></small>
                            </h1>}
                            {sharedText.SCORE ==='Sleep' && <h1 className="font-60">
                              {(ouraData?.total_sleep_duration/60/60 || 0)?.toFixed(2)} <small className="font-30">Hours</small>
                            </h1>}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="ScroeIcon">
                              <DynamicIcon />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid container spacing={2} item xs={12} md={6}>
                      <Grid item xs={12} md={6}>
                        <div className="innerCard">
                          <p>{sharedText.CARD1}</p>
                          { sharedText.SCORE =='Readiness' && <h1>{ouraData?.readiness?.contributors?.resting_heart_rate || 0} bpm</h1>}
                          { sharedText.SCORE =='Sleep' && <h1>{(ouraData?.deep_sleep_duration/60/60 || 0)?.toFixed(2)}</h1>}
                          { sharedText.SCORE =='Activity' && <h1>{(ouraData?.average_met_minutes || 0)?.toFixed(2)} /min</h1>}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="innerCard">
                          <p>{sharedText.CARD2}</p>
                         {sharedText.SCORE =='Readiness' && <h1>{ouraData?.readiness?.contributors?.hrv_balance || 0}</h1>}
                         { sharedText.SCORE =='Sleep' && <h1>{(ouraData?.time_in_bed/60/60 || 0)?.toFixed(2)} /h</h1>}
                         { sharedText.SCORE =='Activity' && <h1>{ouraData?.total_calories || 0}</h1>}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="innerCard">
                          <p>{sharedText.CARD3}</p>
                          { sharedText.SCORE =='Readiness' && <h1>{ouraData?.readiness?.contributors?.body_temperature || 0 +' F'}</h1>}
                          { sharedText.SCORE =='Sleep' &&<h1>{ouraData?.efficiency || 0}%</h1>}
                          { sharedText.SCORE =='Activity' &&<h1>{ouraData?.equivalent_walking_distance || 0}</h1>}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="innerCard">
                          <p>{sharedText.CARD4}</p>
                          {sharedText.SCORE =='Readiness' && <h1>{(ouraData?.average_breath || 0)?.toFixed(2)} /min</h1>}
                          {sharedText.SCORE =='Sleep' && <h1>{ouraData?.readiness?.contributors?.resting_heart_rate || 0} bpm</h1>}
                          {sharedText.SCORE =='Activity' && <h1>{ouraData?.steps || 0}</h1>}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  {/* <h4 className="mb-10">{sharedText.SCORE} contributors</h4> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {sharedText.SCORE =='Sleep' && <div className="innerCard stayActive">
                        <div className="d-set">
                          <h4>{sharedText.CRADBOX1}</h4>
                          <h4 className="stayReport">{(ouraData?.total_sleep_duration/60/60 || 0)?.toFixed(2)} h </h4>
                        </div>
                        <div className="stayActive_progress">
                          <div style={{ width: ((ouraData?.total_sleep_duration/ouraData?.time_in_bed || 0)*100)+'%' }}></div>
                        </div>
                      </div>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      { sharedText.SCORE =='Sleep' && <div className="innerCard stayActive">
                        <div className="d-set">
                          <h4>{sharedText.CRADBOX2}</h4>
                          <h4 className="stayReport">{ouraData?.efficiency || 0}%</h4>
                        </div>
                        <div className="stayActive_progress">
                          <div style={{ width: (ouraData?.efficiency || 0)+'%' }}></div>
                        </div>
                      </div>}
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                      { sharedText.SCORE =='Sleep' && <div className="innerCard stayActive stayAlert">
                        <div className="d-set">
                          <h4>{sharedText.CRADBOX3}</h4>
                          <h4 className="stayReport">Optimal</h4>
                        </div>
                        <div className="stayActive_progress">
                          <div style={{ width: "40%" }}></div>
                        </div>
                      </div>}
                    </Grid> */}
                    {/* <Grid item xs={12} md={6}>
                      {sharedText.SCORE =='Sleep' && <div className="innerCard stayActive">
                        <div className="d-set">
                          <h4>{sharedText.CRADBOX4}</h4>
                          <h4 className="stayReport">Optimal</h4>
                        </div>
                        <div className="stayActive_progress">
                          <div style={{ width: "90%" }}></div>
                        </div>
                      </div>}
                    </Grid> */}
                    {/* <Grid item xs={12} md={6}>
                      { sharedText.SCORE =='Sleep' &&<div className="innerCard stayActive">
                        <div className="d-set">
                          <h4>{sharedText.CRADBOX5}</h4>
                          <h4 className="stayReport">Optimal</h4>
                        </div>
                        <div className="stayActive_progress">
                          <div style={{ width: "50%" }}></div>
                        </div>
                      </div>}
                    </Grid> */}
                  </Grid>
            </section>
  )
}

export default OuraringShare
