import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from "@react-oauth/google"
import Routes from './routes';
import 'react-loading-skeleton/dist/skeleton.css'
import './index.scss';
import "react-toastify/dist/ReactToastify.css";
import { GOOGLE_CLIENT_ID } from './utils/constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
  </GoogleOAuthProvider>
);