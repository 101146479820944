import React from 'react';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
  marginTop: theme => theme.spacing(20),
  textAlign: 'center',
});

const UnderConstructionPage = () => {
  return (
    <StyledContainer>
      <Typography variant="h3" gutterBottom sx={{marginTop: '50px'}}>
        This page is under construction
      </Typography>
      <Typography variant="body1">
        We apologize for the inconvenience. Please check back later.
      </Typography>
    </StyledContainer>
  );
};

export default UnderConstructionPage;
