import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  _chatWithDoctor,
  _followUpMessages,
  _getGoogleAds,
  _getVideoLinks,
  _getAudio,
  _getTextAudio,
  _getSessionMessages,
  getsubscriptionStatus,
  _browseLinks,
  _streamAudio,
} from "../../api/common";
import { MessageBox } from "react-chat-elements";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PauseCircleOutlineTwoToneIcon from "@mui/icons-material/PauseCircleOutlineTwoTone";
import PlayCircleOutlineTwoToneIcon from '@mui/icons-material/PlayCircleOutlineTwoTone';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import VideoThumbnail from "../Shared/VideoThumbnails";
import { ThreeDots, TailSpin, ColorRing } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatText } from "../../utils/methods";
import { voiceName } from "../../utils/constants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ChoiceClipsWidget from "../Shared/VideoScript";
import bioHackerSong from "../../assets/Dr. Biohackersong1.mp3";
import { DR_BIOHACKER } from "../../utils/constants";

import RightPanelForm from "./RightPanelForm";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Home({ isLoggedIn, isInfoMenu, setIsInfoMenu }) {
  const [inputText, setInputText] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([
    {
      position: "left",
      type: "text",
      title: "Kursat",
      text: "Hello there, I'm Dr BioHacker.",
    },
    {
      position: "left",
      type: "text",
      title: "Kursat",
      text: "How can I assist you today?",
    },
  ]);
  const [value, setValue] = React.useState(0);
  const [videos, setVideos] = useState([]);
  const [blogLinks, setBlogLinks] = useState([]);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [songPlaying, setSongPlaying] = useState(false);
  const [leftMessageCount, setLeftMessageCount] = useState(20);
  const [isDisabled, setIsDisabled] = useState(false);
  const [adDetails, setAdDetails] = useState([]);
  const bottomVideoContent = useRef(null);
  const [height, setHeight] = useState(0);
  const [infoHeight, setInfoHeight] = useState(0);
  const [isGeneratingLink, setIsGeneratingLinks] = useState(false);
  const [otherLinks, setOtherLinks] = useState([]);
  const audioRef = useRef(null);
  const [count, setCount] = useState(0);
  const [ouraLogin, setOuraLogin] = useState(true);
  const messageRef = useRef(null);
  const [loadingAudio, setAudioLoading] = useState(false);


  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoPlay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onInputChange = (e) => {
    setInputText(e.target.value);
  };

  const onTextSubmit = async (e) => {
    if (isDisabled && isLoggedIn) {
      toast.error("Your subscription has expired. Please review our pricing options.");
      return;
    }
    if (leftMessageCount === 0 && !isLoggedIn) {
      toast.error("Your free message quota is used up. Please log in to continue.");
      return;
    }

    let newChatItem;
    const session_id = localStorage.getItem("sessionId");
    const accessToken = localStorage.getItem("access_token");

    if (typeof e !== "string") {
      e.preventDefault();
      if (inputText === "") {
        return
      }

      if (inputText !== "") {
        newChatItem = {
          data: {
            message: inputText,
          },
        };

        if (session_id) {
          newChatItem["id"] = { sessionId: session_id };
        }

        if (accessToken) {
          newChatItem["token"] = accessToken;
        }

        setInputText("");
      }
      setMessages((prevData) => [
        ...prevData,
        {
          position: "right",
          type: "text",
          title: "Kursat",
          text: inputText,
        },
      ]);
    } else {
      newChatItem = {
        data: {
          message: e,
        },
      };

      if (session_id) {
        newChatItem["id"] = { sessionId: session_id };
      }

      if (accessToken) {
        newChatItem["token"] = accessToken;
      }

      setInputText("");
      setMessages((prevData) => [
        ...prevData,
        {
          position: "right",
          type: "text",
          title: "Kursat",
          text: e,
        },
      ]);
    }

    try {
      setLoadingMsg(true);
      const res = await _chatWithDoctor(newChatItem);
      // const medical_res = await _createMedicalInformation({"personalData":formData}, localStorage.getItem("access_token"))

      // console.log(medical_res)

      if (isLoggedIn && !localStorage.getItem("sessionId")) {
        setSessionId(res?.data?.data?.loginSession);
        localStorage.setItem("sessionId", res?.data?.data?.loginSession);
      }
      setLeftMessageCount(res?.data?.data?.count);
      setMessages((prevData) => [
        ...prevData,
        {
          position: "left",
          type: "text",
          title: "Kursat",
          text: res?.data?.data?.response,
        },
      ]);

      textToSpeech(res.data.data.response, messages.length + 1, true);

      getFollowUpMessages(res?.data?.data?.response);
      if (res?.data?.data?.is_browsing_needed) {
        await browseLinks(newChatItem?.data?.message);
      }

      setLoadingMsg(false);
    } catch (err) {
      console.log("error occurred :", err);
    }
  };

  const loadMessages = async () => {
    const session_id = localStorage.getItem("sessionId");

    let res;
    try {
      if (session_id) {
        res = await _getSessionMessages(session_id);
      } else {
        res = await _getSessionMessages();
      }

      let transformedArray = await res?.data?.data?.messages?.map((item) => {
        const position = item.role === "user" ? "right" : "left";

        return {
          position: position,
          type: "text",
          title: "Kursat",
          text: item.content,
        };
      });
      setLeftMessageCount(res?.data?.data?.count);
      setMessages([...messages, ...transformedArray]);
    } catch (error) {
      console.log("error on messages api :", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('oura_access_token')) {
      setOuraLogin(false);
    }
    loadMessages();
  }, []);
  function getBrowserName() {
    const userAgent = navigator.userAgent;
    const patterns = {
      chrome: /chrome/i,
      firefox: /firefox/i,
      safari: /safari/i,
      ie: /msie|trident/i,
      edge: /edg|edgehtml/i,
      opera: /opera/i,
    };
    for (const key in patterns) {
      if (patterns[key].test(userAgent)) {
        if (key === "chrome" && /edg|edga/i.test(userAgent)) {
          return "edge";
        } else {
          return key;
        }
      }
    }
    return "unknown";
  }

  const textToSpeech = async (speechData, index, play) => {
    if (!play) {
      // window.speechSynthesis.cancel();
      messageRef.current.pause();
      setIsPlaying(-1);
    } else {
      try {
        setAudioLoading(true)
        setIsPlaying(index);
        const startTime = performance.now();
        const response = await _streamAudio({ message: speechData });
        const endTime = performance.now();
        const elapsedTime = endTime - startTime;
        console.log(`Time taken to fetch audio: ${elapsedTime / 1000} seconds`);

        console.log(response)
        const audioUrl = URL.createObjectURL(response.data);
        setAudioLoading(false)
        if (audioRef.current) {
          messageRef.current.src = audioUrl;
          messageRef.current.play();
        }

      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    const handleAudioEnd = () => {
      setIsPlaying(-1);
    };

    const currentAudioRef = messageRef.current;
    if (currentAudioRef) {
      currentAudioRef.addEventListener('ended', handleAudioEnd);
    }

    return () => {
      if (currentAudioRef) {
        currentAudioRef.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, [messageRef]);

  useEffect(() => {
    function setupSpeechSynthesis() {
      const browserName = getBrowserName();
      const availableVoices = window.speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        let defaultVoice;
        if (browserName === "safari") {
          defaultVoice = availableVoices.find(
            (voice) => voice.voiceURI === voiceName[browserName]
          );
        } else {
          defaultVoice = availableVoices.find(
            (voice) => voice.name === voiceName[browserName]
          );
        }
        // console.log(defaultVoice);
        setSelectedVoice(defaultVoice);
      } else {
        window.speechSynthesis.addEventListener(
          "voiceschanged",
          setupSpeechSynthesis
        );
      }
    }

    setupSpeechSynthesis();

    window.addEventListener("beforeunload", cancelSpeechSynthesis);

    return () => {
      window.removeEventListener("beforeunload", cancelSpeechSynthesis);
      cancelSpeechSynthesis();
    };
  }, []);

  const cancelSpeechSynthesis = () => {
    window.speechSynthesis.cancel();
  };

  const getVideoLinks = async () => {
    await _getVideoLinks().then((resp) => {
      if (resp.status === 200) {
        setVideos(resp?.data?.video_link);
        setOtherLinks(resp?.data?.web_link);
      } else {
        console.err(resp);
      }
    });
  };

  const getFollowUpMessages = async (currentMsg) => {
    const data = {
      message: currentMsg,
    };
    try {
      const res = await _followUpMessages(data);
      if (res.status === 200) {
        function convertStringToArray(dataString) {
          // Split the string by the points
          const dataArray = dataString.split(/\d+\.\s+/).filter(Boolean);
          // Remove quotes from each element in the array
          const trimmedArray = dataArray.map((item) =>
            item.replace(/"/g, "").trim()
          );
          return trimmedArray;
        }

        const followupMessagesinArray = await convertStringToArray(
          res?.data?.response
        );

        for (let i = 0; i < followupMessagesinArray.length; i++) {
          setTimeout(() => {
            setMessages((prevData) => [
              ...prevData,
              {
                position: "right",
                type: "text",
                followup: true,
                title: "Kursat",
                text: followupMessagesinArray[i],
              },
            ]);
          }, 500 * i);
        }
      }
    } catch (error) { }
  };

  const getGoogleAds = () => {
    _getGoogleAds().then((resp) => {
      if (resp.status === 200) {
        setAdDetails(resp?.data?.data);
      } else {
        console.err(resp);
      }
    });
  };

  useEffect(() => {
    getGoogleAds();
  }, []);

  useEffect(() => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
    verifyExpireTimeFromLocal();
  }, [messages]);

  const verifyExpireTimeFromLocal = () => {
    const expirationTime = localStorage.getItem("subscriptionExpireTime");
    const currentTime = new Date().getTime();
    if (
      !expirationTime ||
      currentTime > expirationTime ||
      expirationTime == "null"
    ) {
      localStorage.removeItem("subscriptionExpireTime");
      setIsDisabled(true);
      return null;
    }
  };

  const calculateHeight = async () => {
    const sectionElement = await document.getElementById(
      "bottom-video-content"
    );
    if (sectionElement) {
      const sectionHeight = sectionElement.getBoundingClientRect().height;
      setHeight(sectionHeight);
    }
  };

  const calculateInfoHeight = async () => {
    const sectionElement = document.getElementById("info-area");
    if (sectionElement) {
      const sectionHeight = sectionElement.getBoundingClientRect().height;
      setInfoHeight(sectionHeight);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      calculateHeight();
      calculateInfoHeight();
    }, 5);
  }, [videos]);

  useEffect(() => {
    function handleResize() {
      calculateHeight();
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const storeSubscriptionStatus = async () => {
    try {
      if (isLoggedIn) {
        const getCouter = localStorage.getItem('couter')
        const response = (await getsubscriptionStatus()).data;
        if (response.status === true) {
          if (response.data.expiry_date) {
            localStorage.setItem(
              "subscriptionExpireTime",
              response.data.expiry_date
            );
            localStorage.setItem(
              "subscriptionStatus",
              response.data.status
            );
            if (count === 0 && !getCouter) {
              localStorage.setItem('couter', count + 1)
              setCount(count + 1)
              window.location.reload();
            }

          }
        }
      }
    } catch (error) {
      console.log("server error: ", error);
    }
  };

  const browseLinks = async (textData) => {
    const data = {
      query: textData,
    };
    try {
      setIsGeneratingLinks(true);
      const res = await _browseLinks(data);
      setIsGeneratingLinks(false);
      setBlogLinks(res?.data?.data);
    } catch (error) {
      setIsGeneratingLinks(true);
    }
  };

  useEffect(() => {
    getVideoLinks();
    storeSubscriptionStatus();
  }, []);

  const togglePlay = () => {
    if (songPlaying) {
      audioRef.current.pause();
      setSongPlaying(false);
    } else {
      audioRef.current.play();
      setSongPlaying(true);
    }
  };

  return (
    <>
      <div className="home-wrap">
        <audio ref={messageRef} key={messageRef} />
        <ToastContainer theme="colored" />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} className="grid_chat_parent">
            <Grid item className="left_chat">
              <div className="chat-wrap">
                <div
                  className="message-wrap"
                  id="chat-container"
                  style={{
                    height: `calc(100dvh - ${170 + height + infoHeight}px)`,
                  }}
                >
                  {messages?.map(
                    ({ position, type, title, text, followup }, index) => {
                      return (
                        <React.Fragment key={index}>
                          {position === "left" ? (
                            <>
                              <div className="msg-wrapper-parent dr-msg">
                                <div className="user-avatar">
                                  {" "}
                                  <Avatar>Dr.</Avatar>
                                </div>
                                <MessageBox
                                  position={position}
                                  title=""
                                  type={type}
                                  text={text}
                                />
                                <div className="speaker-icon">
                                  {isPlaying === index && loadingAudio ? (
                                    <TailSpin
                                      height="24"
                                      width="24"
                                      color="#555"
                                    /> // Replace this with your loader icon
                                  ) : (
                                    isPlaying === index ? (
                                      <PauseCircleOutlineTwoToneIcon
                                        color="grey"
                                        onClick={() => textToSpeech(text, index, false)}
                                      />
                                    ) : (
                                      <VolumeUpIcon
                                        color="grey"
                                        onClick={() => textToSpeech(text, index, true)}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          ) : followup ?? followup ? (
                            <div className="msg-wrapper-parent user-msg follow-up-messages">
                              <MessageBox
                                position={position}
                                title=""
                                type={type}
                                text={text}
                                onClick={() => {
                                  // setInputText(text)
                                  onTextSubmit(text);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="msg-wrapper-parent user-msg">
                              <MessageBox
                                position={position}
                                title=""
                                type={type}
                                text={text}
                              />
                              <div className="user-avatar">
                                {" "}
                                <Avatar
                                //    sx={{ bgcolor: deepOrange[500] }}
                                >
                                  <PersonOutlineOutlinedIcon />
                                </Avatar>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                  {loadingMsg ? (
                    <div className="msg-wrapper-parent dr-msg loading-msg">
                      <div className="user-avatar">
                        {" "}
                        <Avatar
                        //    sx={{ bgcolor: deepOrange[500] }}
                        >
                          Dr.
                        </Avatar>
                      </div>
                      <MessageBox
                        position={"left"}
                        title=""
                        type={"text"}
                        text={
                          <ThreeDots
                            visible={true}
                            height="25"
                            width="35"
                            color="#4fa94d"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <Stack sx={{ width: "100%", mb: 1 }} spacing={2} id="info-area">
                  {!isLoggedIn ? (
                    <Alert
                      severity="info"
                      className="alertSm"
                      md={{ fontSize: "14px" }}
                    >
                      {`${leftMessageCount} messages left. Signup for free to send
                      more messages. Message limits will reset on the first day
                      of each month. `}
                      <NavLink to="/login">CLICK HERE</NavLink> to get unlimited
                      advice and info from {DR_BIOHACKER}
                    </Alert>
                  ) : null}
                </Stack>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <TextField
                    //   label="With normal TextField"
                    placeholder="Send a message"
                    id="outlined-start-adornment"
                    sx={{ width: "100%" }}
                    value={inputText}
                    className="chatInput"
                    // placeholder="Send chat"
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => {
                      e.code === "Enter" && onTextSubmit(e);
                    }}
                  />
                  <Button
                    variant="contained"
                    className="sendButton"
                    disabled={loadingMsg}
                    onClick={(e) => onTextSubmit(e)}
                    endIcon={<SendIcon />}
                    sx={{ width: "20ch" }}
                  >
                    Send
                  </Button>
                </Stack>
                {ouraLogin && <div className="panel mt-20 text-center">
                  <b>
                    Dr. Biohacker can chat with you about your Oura ring data -
                    <NavLink rel="noopener noreferrer" to={"/ouraring"}> Click here</NavLink>
                  </b>
                </div>}
                <div id="bottom-video-content" className="bottom-video-content">
                  <Stack
                    sx={{ width: "100%", marginTop: "10px" }}
                    spacing={2}
                    ref={bottomVideoContent}
                  >
                    <Alert severity="warning" sx={{ color: "#d66c6c" }}>
                      Since {DR_BIOHACKER} has all of the latest info accepted
                      by medical authorities like the CDC and FDA as well as
                      medical associations, as biohackers we all know that many
                      times this is NOT the latest info that is provable, so
                      please enjoy the videos below from the leaders of the
                      biohacking community.
                    </Alert>
                  </Stack>
                  <Stack sx={{ width: "100%", mt: 2, position: "relative" }}>
                    <Slider className="videos-slick" {...settings}>
                      {videos?.map((val, index) => {
                        return (
                          <div key={index}>
                            <div>
                              <VideoThumbnail
                                video_url={val?.video_url}
                                thumbnail_url={val?.thumbnail_url}
                                index={index}
                                description={val?.description}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </Stack>
                </div>
                <Box>
                  <ChoiceClipsWidget />
                </Box>
                <hr />
                <div className=" mt-20 mb-20">
                  <Stack
                  // sx={{
                  //   minHeight: blogLinks?.length > 4 ? "190px" : "auto",
                  // }}
                  >
                    <ul className="web_link_list">
                      {otherLinks?.map(
                        ({ title, url, link_position }, i) =>
                          link_position === "LEFT BOTTOM" && (
                            <li key={i}>
                              <a href={url} target="_blank" rel="noreferrer">
                                {title}
                              </a>
                            </li>
                          )
                      )}
                    </ul>
                  </Stack>
                </div>
              </div>
            </Grid>
            <Grid
              item
              className={`right_article ${isInfoMenu ? "openInfo" : " "}`}
            >
              <div className="panel d-set">
                <h4 className="play_title">
                  Click here for Dr. BioHacker song
                </h4>
                <audio
                  key={audioRef}
                  ref={audioRef}
                  onPlay={() => setSongPlaying(true)}
                  onPause={() => setSongPlaying(false)}
                  className="mt-20 w-100"
                >
                  <source src={bioHackerSong} type="audio/mpeg" />
                </audio>
                <div onClick={togglePlay} className="play_wrap">
                  {songPlaying ? <PauseCircleOutlineTwoToneIcon /> : <PlayCircleOutlineTwoToneIcon />}
                </div>

              </div>
              <div className="panel mt-20">
                <Typography variant="h6" gutterBottom>
                  <Stack
                    spacing={2}
                    mb={2}
                    direction="row"
                    style={{ alignItems: "", lineHeight: "1" }}
                  >
                    <BookTwoToneIcon color="primary" /> {DR_BIOHACKER}'s
                    Additional Information from the web (based on your
                    questions)
                  </Stack>
                </Typography>

                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="info">
                    After you start your conversation, {DR_BIOHACKER} will
                    collect research materials here.
                  </Alert>
                </Stack>
                <Stack
                  sx={{ minHeight: blogLinks?.length > 4 ? "190px" : "auto" }}
                >
                  {isGeneratingLink ? (
                    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#f7f7f7">
                      <ul className="web_link_list">
                        {[...Array(5)].map((_, index) => (
                          <li key={index}>
                            <Skeleton count={1} />
                          </li>
                        ))}
                      </ul>
                    </SkeletonTheme>
                  ) : (
                    <ul className="web_link_list">
                      {blogLinks?.map(({ title, href }, i) => (
                        <li key={i}>
                          <a href={href} target="_blank" rel="noreferrer">
                            {title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </Stack>
              </div>

              {adDetails?.map((val, index) => {
                return (
                  <div className="panel mt-20">
                    <a
                      href={val?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="show-img-box"
                    >
                      <img
                        src={val?.ad_image_url}
                        alt={`Video Thumbnail ${val?.ad_image_url}`}
                      />
                    </a>
                  </div>
                );
              })}
              {isLoggedIn &&
                <div className="mt-20 panel">
                  <RightPanelForm />
                </div>
              }
              {/* <Box sx={{ width: "100%" }}>

                <CustomTabPanel value={value} index={0}>
                  <div className="mt-20">
                    <Typography variant="h6" gutterBottom>
                      <Stack
                        spacing={1}
                        direction="row"
                        style={{ alignItems: "center" }}
                      >
                        <FilterTiltShiftTwoToneIcon color="primary" />{" "}
                        <span> Core</span>
                      </Stack>
                    </Typography>
                  </div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mb: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      label="Age"
                      size="small"
                      id="outlined-end-adornment"
                      sx={{ mb: 1, mr: 1, width: "25ch" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Yr</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Weight"
                      size="small"
                      id="outlined-end-adornment"
                      sx={{ mb: 1, mr: 0, width: "25ch" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">lb</InputAdornment>
                        ),
                      }}
                    />
                    <Stack direction={"row"} spacing={2} sx={{ width: "auto" }}>
                      <Stack direction={"row"} spacing={1}>
                        <TextField
                          label="Height"
                          size="small"
                          id="outlined-end-adornment"
                          sx={{ mb: 1, mr: 1, width: "14ch" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">ft</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label=""
                          size="small"
                          id="outlined-end-adornment"
                          sx={{ mb: 1, mr: 1, width: "10ch" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">in</InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label="Symptoms"
                          size="small"
                          id="filled-basic"
                          variant="filled"
                          sx={{ mb: 1, mr: 0, width: "25ch" }}
                        />
                      </Stack>
                    </Stack>
                    <TextField
                      label="Allergies"
                      size="small"
                      id="filled-basic"
                      variant="filled"
                      sx={{ mb: 1, mr: 1, width: "25ch" }}
                    />
                    <TextField
                      label="Medications"
                      size="small"
                      id="filled-basic"
                      variant="filled"
                      sx={{ mb: 1, mr: 0, width: "25ch" }}
                    />
                  </Box>

                  <div className="mt-20">
                    <Typography variant="h6" gutterBottom>
                      <Stack
                        spacing={1}
                        direction="row"
                        style={{ alignItems: "center" }}
                      >
                        <BiotechTwoToneIcon color="primary" />{" "}
                        <span>Vitals</span>
                      </Stack>
                    </Typography>
                  </div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { mb: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      label="Temperature"
                      size="small"
                      id="outlined-end-adornment"
                      sx={{ mb: 1, mb: 1, width: "25ch" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">°F</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Heart rate"
                      size="small"
                      id="outlined-end-adornment"
                      sx={{ ml: 1, mb: 1, width: "25ch" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">BPM</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Respiratory Rate"
                      size="small"
                      id="outlined-end-adornment"
                      sx={{ mb: 1, mr: 0, width: "25ch" }}
                    />
                    <TextField
                      label="Oxygen Saturation"
                      size="small"
                      id="outlined-end-adornment"
                      sx={{ ml: 1, mr: 0, width: "25ch" }}
                    />
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Box></Box>
                </CustomTabPanel>
              </Box> */}
              {!isLoggedIn ? (
                <div className="panel mt-20">
                  <h4>
                    Would you like to get personalized answers from Dr. Bio
                    Hacker
                  </h4>
                  <div>
                    <NavLink className="btn btn-def" to={"/login"}>
                      Click Here{" "}
                    </NavLink>
                  </div>
                </div>
              ) : null}
              <div className="panel mt-20">
                <Stack
                // sx={{ minHeight: blogLinks?.length > 4 ? "190px" : "auto" }}
                >
                  <ul className="web_link_list">
                    {otherLinks?.map(
                      ({ title, url, link_position }, i) =>
                        link_position === "RIGHT BOTTOM" && (
                          <li key={i}>
                            <a href={url} target="_blank" rel="noreferrer">
                              {title}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default Home;
