import React, { useEffect, useState } from 'react';
import './Videoscript.scss'

const ChoiceClipsWidget = () => {
  const [items, setItems] = useState([]);

  const conf = {
    rows: 2,
    cols: 3,
    backgroundColor: 'rgb(240, 240, 240)',
    textColor: 'rgb(0, 0, 0)',
    borderColor: '#DDD',
    displayContent: '0',
    contentSort: '0',
    contentType: '0',
    showTitle: '1',
    showThumb: '1',
    widgetID: 'wWidget_61daf07f6c',
    layout: 'basic'
  };
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://choiceclips.whatfinger.com/widgets/server/azwidget-videos.php?widgetID=61daf07f6c&width=${(100 - (0.5 * conf.cols * 2)) / conf.cols}&cols=${conf.cols}&rows=${conf.rows}`
        );
        const data = await response.json();
        setItems(data.items);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const buildItem = (url, title, textColor, thumbUrl, width, margin, padding, showTitle, showThumb,key) => {
    return (
      <div
        key={key}
        className='innerVideo'
        style={{
          width,
          margin,
          padding,
          color: textColor
        }}
      >
        <a className='videothumImage' target='_blank' href={url}>
            {showThumb && <img src={thumbUrl} alt={title} />}
        </a>
        
        <a className='videoTitleLinks' target='_blank' href={url}>{showTitle && <h3>{title}</h3>}</a>
      </div>
    );
  };

  const addStyles = (layout, borderColor, showThumb, cols, widgetID) => {
    let margin, padding;
    switch (layout) {
      case 'small_image':
        padding = '0px 0px 15px 0px';
        break;
      default:
        padding = '0px';
        break;
    }

    return (
      <style>
        {`
          .${widgetID} {
            display: grid;
            grid-template-columns: repeat(${cols}, 1fr);
            grid-gap: 10px;
            background-color: ${borderColor};
            padding: ${showThumb ? '10px' : '0'};
          }
          .${widgetID} > div {
            margin: ${margin};
            padding: ${padding};
          }
        `}
      </style>
    );
  };

  return (
    <div id="wWidget_61daf07f6c" className="wWidget_wrapper">
      {addStyles('basic', conf.borderColor, conf.showThumb, conf.cols, conf.widgetID)}
      <div className="wWidget_top">
        {items.map((item, index) =>
          buildItem(
            item.url,
            item.title,
            conf.textColor,
            item.thumb_url,
            `${(100 - (0.5 * conf.cols * 2)) / conf.cols}%`,
            '0 0.5% 20px 0.5%',
            '0px',
            conf.showTitle,
            conf.showThumb,
            index
          )
        )}
      </div>
    </div>
  );
};

export default ChoiceClipsWidget;