const setData = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
}

const getData = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

const removeData = (key) => {
    return localStorage.removeItem(key);
}

const clearCache = () => {
    localStorage.clear();
}

export { setData, getData, removeData, clearCache };
