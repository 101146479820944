import React from "react";
import { DR_BIOHACKER } from "../utils/constants";

const TermsOfUse = () => {
  return (
    <div className="privacy-policy terms-of-use">
      <h3 className="page-heading">Terms of Service for {DR_BIOHACKER}</h3>
      <p>Effective Date: 4/10/2024</p>
      <p>
        Welcome to {DR_BIOHACKER}, located at 6805 Lebanon Rd Suite 512, Frisco,
        Texas 75034. The following terms and conditions govern your use of our
        website and the medical advice and information it provides. By accessing
        or using the services of {DR_BIOHACKER}, you agree to be bound by these
        Terms of Service and our Privacy Policy. If you do not agree with any
        part of these terms, you must not use our website.
      </p>
      <h4>1. Medical Disclaimer</h4>
      <p>
        {DR_BIOHACKER} is committed to providing high-quality health and wellness
        information. However, the content, advice, and information provided
        through our website are not intended to replace professional medical
        advice, diagnosis, or treatment. Always seek the advice of your
        physician or other qualified health providers with any questions you may
        have regarding a medical condition.
      </p>
      <p>
        Never disregard professional medical advice or delay seeking it because
        of something you have read on the {DR_BIOHACKER} website. If you think
        you may have a medical emergency, call your doctor, go to the emergency
        department, or call emergency services immediately.
      </p>
      <h4>2. Use of Information</h4>
      <p>
        The information provided on the {DR_BIOHACKER} website is for general
        informational purposes only. It is designed to support, not replace, the
        relationship that exists between you and your healthcare providers. You
        are encouraged to discuss any information you obtain from {DR_BIOHACKER}
        with your primary care physician, and to use it as a basis for any
        health-related decisions.
      </p>
      <h4>3. User Responsibilities</h4>
      <p>
        By using the {DR_BIOHACKER} website, you agree to:
        
            <ul>
              <li>
                Not use the information provided for diagnosing or treating any
                health problem or disease.
              </li>
              <li>
                Verify any information obtained from this website with other
                sources, and consult with your healthcare provider regarding any
                health-related questions or issues.
              </li>
              <li>
                Acknowledge that the health information on the {DR_BIOHACKER}
                website is provided “as is” and without warranties of any kind,
                either express or implied.
              </li>
            </ul>
      </p>
      <h4>4. Limitation of Liability</h4>
      <p>
        {DR_BIOHACKER}, its affiliates, and its information providers shall not
        be liable for any damages, including but not limited to direct,
        indirect, incidental, punitive, and consequential damages, arising out
        of the use or inability to use the services or information provided.
      </p>
      <h4>5. Changes to Terms of Service</h4>
      <p>
        {DR_BIOHACKER} reserves the right, at its sole discretion, to modify or
        replace these Terms of Service at any time.
      </p>
    </div>
  );
};

export default TermsOfUse;
