import React, { useState, useEffect, useRef } from 'react'
import Grid from "@mui/material/Grid";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import BiotechTwoToneIcon from "@mui/icons-material/BiotechTwoTone";
import FilterTiltShiftTwoToneIcon from "@mui/icons-material/FilterTiltShiftTwoTone";
import TextField from "@mui/material/TextField";
import MonitorHeartTwoToneIcon from '@mui/icons-material/MonitorHeartTwoTone';
import { _createMedicalInformation, _updateMedicalInformation, _getMedicalInformation } from '../../api/common';


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RightPanelForm() {
  const [value, setValue] = React.useState(0);
  const timeRef = useRef(null);

  const [formData, setFormData] = useState({
    age: { value: '', unit: 'Yr' },
    weight: { value: '', unit: 'lb' },
    heightFeet: { value: '', unit: 'ft' },
    heightInches: { value: '', unit: 'in' },
    symptoms: { value: '', unit: '' },
    allergies: { value: '', unit: '' },
    medications: { value: '', unit: '' },
    temperature: { value: '', unit: 'F' },
    heartRate: { value: '', unit: 'BPM' },
    respiratoryRate: { value: '', unit: 'BPM' },
    oxygenSaturation: { value: '', unit: 'BPM' },
    waistCircumference: { value: '', unit: 'in' },
    hipCircumference: { value: '', unit: 'in' },
    systolicBloodPressure: { value: '', unit: 'mmHg' },
    diastolicBloodPressure: { value: '', unit: 'mmHg' },
    albumin: { value: '', unit: 'g/dL' },
    ALT: { value: '', unit: 'U/L' },
    AST: { value: '', unit: 'U/L' },
    BUN: { value: '', unit: 'mg/dL' },
    calcium: { value: '', unit: '' },
    creatinine: { value: '', unit: 'mg/dL' },
    glucose: { value: '', unit: 'mg/dL' },
    HbA1c: { value: '', unit: 'mmol/mol' },
    potassium: { value: '', unit: 'mEq/L' },
    sodium: { value: '', unit: 'mEq/L' },
    triglycerides: { value: '', unit: 'mg/dL' },
    LDL: { value: '', unit: 'mg/dL' },
    HDL: { value: '', unit: 'mg/dL' },
    eGFR: { value: '', unit: 'mL/min/1.73m2' },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await _getMedicalInformation(localStorage.getItem("access_token"));
        const responseData = {
          age: { value: response.data.data.age, unit: 'Yr' },
          weight: { value: response.data.data.weight, unit: 'lb' },
          heightFeet: { value: response.data.data.height_feet, unit: 'ft' },
          heightInches: { value: response.data.data.height_inches, unit: 'in' },
          symptoms: { value: response.data.data.symptoms, unit: '' },
          allergies: { value: response.data.data.allergies, unit: '' },
          medications: { value: response.data.data.medications, unit: '' },
          temperature: { value: response.data.data.temperature, unit: 'F' },
          heartRate: { value: response.data.data.heart_rate, unit: 'BPM' },
          respiratoryRate: { value: response.data.data.respiratory_rate, unit: 'BPM' },
          oxygenSaturation: { value: response.data.data.oxygen_saturation, unit: 'BPM' },
          waistCircumference: { value: response.data.data.waist_circumference, unit: 'in' },
          hipCircumference: { value: response.data.data.hip_circumference, unit: 'in' },
          systolicBloodPressure: { value: response.data.data.systolic_blood_pressure, unit: 'mmHg' },
          diastolicBloodPressure: { value: response.data.data.diastolic_blood_pressure, unit: 'mmHg' },
          albumin: { value: response.data.data.albumin, unit: 'g/dL' },
          ALT: { value: response.data.data.ALT, unit: 'U/L' },
          AST: { value: response.data.data.AST, unit: 'U/L' },
          BUN: { value: response.data.data.BUN, unit: 'mg/dL' },
          calcium: { value: response.data.data.calcium, unit: '' },
          creatinine: { value: response.data.data.creatinine, unit: 'mg/dL' },
          glucose: { value: response.data.data.glucose, unit: 'mg/dL' },
          HbA1c: { value: response.data.data.HbA1c, unit: 'mmol/mol' },
          potassium: { value: response.data.data.potassium, unit: 'mEq/L' },
          sodium: { value: response.data.data.sodium, unit: 'mEq/L' },
          triglycerides: { value: response.data.data.triglycerides, unit: 'mg/dL' },
          LDL: { value: response.data.data.LDL, unit: 'mg/dL' },
          HDL: { value: response.data.data.HDL, unit: 'mg/dL' },
          eGFR: { value: response.data.data.eGFR, unit: 'mL/min/1.73m2' },
        }
        setFormData(responseData);
      } catch (error) {
        console.log("error getting medical information :", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const delayedAPICall = (formData) => {
    clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      _createMedicalInformation({ "personalData": formData }, localStorage.getItem("access_token"))
        .then(response => {
          if (response.data.message == "Medical information exist") {
            _updateMedicalInformation({ "personalData": formData }, localStorage.getItem("access_token"))
              .then(response => {
                const responseData = {
                  age: { value: response.data.data.age, unit: 'Yr' },
                  weight: { value: response.data.data.weight, unit: 'lb' },
                  heightFeet: { value: response.data.data.height_feet, unit: 'ft' },
                  heightInches: { value: response.data.data.height_inches, unit: 'in' },
                  symptoms: { value: response.data.data.symptoms, unit: '' },
                  allergies: { value: response.data.data.allergies, unit: '' },
                  medications: { value: response.data.data.medications, unit: '' },
                  temperature: { value: response.data.data.temperature, unit: 'F' },
                  heartRate: { value: response.data.data.heart_rate, unit: 'BPM' },
                  respiratoryRate: { value: response.data.data.respiratory_rate, unit: 'BPM' },
                  oxygenSaturation: { value: response.data.data.oxygen_saturation, unit: 'BPM' },
                  waistCircumference: { value: response.data.data.waist_circumference, unit: 'in' },
                  hipCircumference: { value: response.data.data.hip_circumference, unit: 'in' },
                  systolicBloodPressure: { value: response.data.data.systolic_blood_pressure, unit: 'mmHg' },
                  diastolicBloodPressure: { value: response.data.data.diastolic_blood_pressure, unit: 'mmHg' },
                  albumin: { value: response.data.data.albumin, unit: 'g/dL' },
                  ALT: { value: response.data.data.ALT, unit: 'U/L' },
                  AST: { value: response.data.data.AST, unit: 'U/L' },
                  BUN: { value: response.data.data.BUN, unit: 'mg/dL' },
                  calcium: { value: response.data.data.calcium, unit: '' },
                  creatinine: { value: response.data.data.creatinine, unit: 'mg/dL' },
                  glucose: { value: response.data.data.glucose, unit: 'mg/dL' },
                  HbA1c: { value: response.data.data.HbA1c, unit: 'mmol/mol' },
                  potassium: { value: response.data.data.potassium, unit: 'mEq/L' },
                  sodium: { value: response.data.data.sodium, unit: 'mEq/L' },
                  triglycerides: { value: response.data.data.triglycerides, unit: 'mg/dL' },
                  LDL: { value: response.data.data.LDL, unit: 'mg/dL' },
                  HDL: { value: response.data.data.HDL, unit: 'mg/dL' },
                  eGFR: { value: response.data.data.eGFR, unit: 'mL/min/1.73m2' },
                }
                setFormData(responseData);
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }, 1500);
  };


  const handleFormData = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: { value: value, unit: prevState[name].unit }
    }));

    delayedAPICall({ ...formData, [name]: { value: value, unit: formData[name].unit } });
  };

  return (
    <>
      <div className='panel_form_wrap'>
        <div>
          <Typography variant="h6" gutterBottom>
            <Stack
              spacing={1}
              direction="row"
              style={{ alignItems: "center" }}
            >
              <MedicalInformationIcon color="primary" />{" "}
              <span> Medical Information </span>
            </Stack>
          </Typography>
          <p className="text-muted ">
            Provide your medical information for more personalized and
            informative suggestions.
          </p>
        </div>

        <Box sx={{ width: "100%" }}>
          <Box
          //   sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="fullWidth"
              className="mt-3 tabs_ui"

            // TabIndicatorProps={sx={{ border: 1, borderColor: "divider", width: "100%" }}}
            >
              <Tab
                label="IMPERIAL"
                className="tabs_in"
                {...a11yProps(0)}
                sx={{ border: 1, borderColor: "divider", width: "100%" }}
              />
              {value === 0 ? (
                <Tab
                  label="METRIC (SI)"
                  className="tabs_in"
                  {...a11yProps(1)}
                  disabled // This disables the tab
                  sx={{ border: 1, borderColor: "divider", width: "100%" }}
                />
              ) : null}
            </Tabs>
          </Box>

          <div className="mt-20">
            <Typography variant="h6" gutterBottom>
              <Stack
                spacing={1}
                direction="row"
                style={{ alignItems: "center" }}
              >
                <FilterTiltShiftTwoToneIcon color="primary" />{" "}
                <span> Core</span>
              </Stack>
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Age"
                  size="small"
                  id="outlined-number"
                  type="number"
                  name="age"
                  value={formData.age.value}
                  onChange={handleFormData}
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">Yr</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Weight"
                  size="small"
                  id="outlined-number"
                  type="number"
                  name="weight"
                  value={formData.weight.value}
                  onChange={handleFormData}
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">lb</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <TextField
                      label="Height"
                      size="small"
                      id="outlined-number"
                      type="number"
                      name="heightFeet"
                      value={formData.heightFeet.value}
                      onChange={handleFormData}
                      sx={{ mb: 1, mr: 1 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className='unit' position="end">ft</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label=""
                      size="small"
                      id="outlined-end-adornment"
                      name="heightInches"
                      value={formData.heightInches.value}
                      onChange={handleFormData}
                      sx={{ mb: 1, mr: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className='unit' position="end">in</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Symptoms"
                  size="small"
                  id="filled-basic"
                  name="symptoms"
                  value={formData.symptoms.value}
                  onChange={handleFormData}
                  variant="filled"
                  sx={{ mb: 1, mr: 0 }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Allergies"
                  size="small"
                  id="filled-basic"
                  name="allergies"
                  value={formData.allergies.value}
                  onChange={handleFormData}
                  variant="filled"
                  sx={{ mb: 1, mr: 1 }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Medications"
                  size="small"
                  id="filled-basic"
                  name="medications"
                  value={formData.medications.value}
                  onChange={handleFormData}
                  variant="filled"
                  sx={{ mb: 1, mr: 0 }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="mt_32">
            <Typography variant="h6" gutterBottom>
              <Stack
                spacing={1}
                direction="row"
                style={{ alignItems: "center" }}
              >
                <MonitorHeartTwoToneIcon color="primary" />{" "}
                <span> Vitals</span>
              </Stack>
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Systolic Blood Pressure"
                  size="small"
                  id="outlined-number"
                  name="systolicBloodPressure"
                  value={formData.systolicBloodPressure.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mmHg</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Diastolic Blood Pressure"
                  size="small"
                  id="outlined-number"
                  name="diastolicBloodPressure"
                  value={formData.diastolicBloodPressure.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mmHg</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Oxygen Saturation"
                  size="small"
                  id="outlined-number"
                  name="oxygenSaturation"
                  value={formData.oxygenSaturation.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">BPM</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Waist Circumference"
                  size="small"
                  id="outlined-number"
                  name="waistCircumference"
                  value={formData.waistCircumference.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">in</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hip Circumference"
                  size="small"
                  id="outlined-number"
                  name="hipCircumference"
                  value={formData.hipCircumference.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">in</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Heart Rate"
                  size="small"
                  id="outlined-number"
                  name="heartRate"
                  value={formData.heartRate.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">BPM</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Temperature"
                  size="small"
                  id="outlined-number"
                  name="temperature"
                  value={formData.temperature.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">F</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Respiratory Rate"
                  size="small"
                  id="outlined-number"
                  name="respiratoryRate"
                  value={formData.respiratoryRate.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">BPM</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="mt_32">
            <Typography variant="h6" gutterBottom>
              <Stack
                spacing={1}
                direction="row"
                style={{ alignItems: "center" }}
              >
                <BiotechTwoToneIcon color="primary" />{" "}
                <span> Lab Test Results</span>
              </Stack>
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="ALT"
                  size="small"
                  id="outlined-number"
                  name="ALT"
                  value={formData.ALT.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">U/L</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Glucose"
                  size="small"
                  id="outlined-number"
                  name="glucose"
                  value={formData.glucose.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mg/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="BUN"
                  size="small"
                  id="outlined-number"
                  name="BUN"
                  value={formData.BUN.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mg/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Calcium"
                  size="small"
                  id="outlined-number"
                  name="calcium"
                  value={formData.calcium.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Creatinine"
                  size="small"
                  id="outlined-number"
                  name="creatinine"
                  value={formData.creatinine.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mg/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Albumin"
                  size="small"
                  id="outlined-number"
                  name="albumin"
                  value={formData.albumin.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">g/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Triglycerides"
                  size="small"
                  id="outlined-number"
                  name="triglycerides"
                  value={formData.triglycerides.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mg/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="HbA1c"
                  size="small"
                  id="outlined-number"
                  name="HbA1c"
                  value={formData.HbA1c.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mmol/mol</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="HDL"
                  size="small"
                  id="outlined-number"
                  name="HDL"
                  value={formData.HDL.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mg/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="eGFR"
                  size="small"
                  id="outlined-number"
                  name="eGFR"
                  value={formData.eGFR.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mL/min/1.73m2</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Potassium"
                  size="small"
                  id="outlined-number"
                  name="potassium"
                  value={formData.potassium.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mEq/L</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="AST"
                  size="small"
                  id="outlined-number"
                  name="AST"
                  value={formData.AST.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">U/L</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Sodium"
                  size="small"
                  id="outlined-number"
                  name="sodium"
                  value={formData.sodium.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mEq/L</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="LDL"
                  size="small"
                  id="outlined-number"
                  name="LDL"
                  value={formData.LDL.value}
                  onChange={handleFormData}
                  type="number"
                  sx={{ mb: 1, mr: 1 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className='unit' position="end">mg/dL</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
    </>
  )
}

export default RightPanelForm