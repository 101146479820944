import React, { useState, useEffect } from "react";

// Utility function to extract YouTube video ID from URL
function getYouTubeVideoId(url) {
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url?.match(regExp);
  return match ? match[1] : null;
}

const VideoThumbnail = ({ video_url,thumbnail_url, index, description }) => {

  return (
    <div className="video-with-links" key={index}>
      <a href={video_url} target="_blank" rel="noopener noreferrer">
        <div className="thumbnail-img">
          <img
            style={{ width: "100%", objectFit: "center" }}
            src={thumbnail_url}
            alt={`Video Thumbnail ${thumbnail_url}`}
          />
        </div>
        <p>{description}</p>
      </a>
    </div>
  );
};

export default VideoThumbnail;
